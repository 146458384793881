<template>
  <div class="right" style="padding-top: 105px">
    <!--头-->
    <div class="header">
      <!--标题-->
      <h6 class="title">找回密码</h6>
      <!--右侧按钮-->
      <div class="title-right">
        <el-button type="text" class="is-black" @click="goBack">返回登录</el-button>
      </div>
    </div>

    <!--表单-->
    <el-form
      ref="Forget"
       :model="Forget"
       :rules="rules"
       size="medium"
    >
      <el-form-item prop="phone">
        <el-input
          v-model="Forget.phone"
          v-on:input="getPhoneString"
          placeholder="手机号"
          maxlength="11"
          @input="changeValue"
        />
      </el-form-item>

      <el-form-item key="vf_input" prop="VerificationCode">
        <div class="d-flex flex-align-h-between">
          <el-input
            v-model.trim="Forget.VerificationCode"
            placeholder="验证码"
            maxlength="6"
          />

          <div class="ml-20" style="width: 130px;flex:0 0 auto">
            <el-button v-if="AuthCode" type="primary" class="width-full" @click="checkCode()">获取验证码</el-button>
            <el-button v-else disabled type="primary" class="width-full again-btn">重新获取({{ auth_time }}s)</el-button>
          </div>
        </div>
      </el-form-item>

      <el-form-item prop="PassWord">
        <el-input
          v-model.trim="Forget.PassWord"
          :type="passwordType ? 'text' : 'password'"
          placeholder="密码"
          maxlength="16"
        >
          <svg-icon
            slot="suffix"
            :icon-class="passwordType ? 'eye-open' : 'eye'"
            @click="passwordType = !passwordType"
          />
        </el-input>
      </el-form-item>

      <el-form-item prop="rePassWord">
        <el-input
          v-model.trim="Forget.rePassWord"
          :type="passwordType2 ? 'text' : 'password'"
          placeholder="重复密码"
          maxlength="16"
        >
        <svg-icon
            slot="suffix"
            :icon-class="passwordType2 ? 'eye-open' : 'eye'"
            @click="passwordType2 = !passwordType2"
          />
        </el-input>
      </el-form-item>
    </el-form>
    <!--备注-->
    <div class="remark">6-16个字符，可包含数字、字母及符号。</div>

    <el-button type="primary" size="medium" @click="ResetPassWord" class="width-full">确 认</el-button>
  </div>
</template>

<script>
import api from '../../api';

export default {
  name: 'forgetPage',
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.Forget.PassWord) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      passwordType: false,
      passwordType2: false,
      Forget: {
        phone: '', // 手机号,
        VerificationCode: '', // 验证码
        PassWord: '', // 密码
        rePassWord: '', // 重复密码
      },
      rightViewType: 0,
      AuthCode: true,
      auth_time: 60,
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9][0-9]{9}$/, message: '请输入正确手机号', trigger: 'blur' },
        ],
        PassWord: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            pattern: /^[\w!@#$%^&*]{6,16}$/,
            message: '请输入6-16位密码，可包含数字、字母、!@#$%^&*_',
            trigger: 'blur',
          },
        ],
        rePassWord: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' },
        ],
        VerificationCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { pattern: /^\d{6}$/, message: '请输入正确验证码格式', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    changeValue(value) {
      this.Forget.phone = value.replace(/[^\d]/g, '');
    },
    getPhoneString() {
      this.Forget.phone = this.Forget.phone.replace(' ', '');
    },
    // 返回登录
    BackLogin() {
      this.$router.push('/login');
    },
    // 验证码手机号格式
    checkPhone() {
      const reg = /^1[3-9][0-9]{9}$/;
      const res = reg.test(this.Forget.phone);
      return res;
    },
    // 获取验证码并判断格式
    checkCode() {
      if (this.Forget.phone.length <= 0) {
        this.$message({
          message: '手机号不能为空',
          type: 'error',
          duration: 1000,
        });
      } else if (!this.checkPhone()) {
        this.$message({
          message: '请输入正确的手机号',
          type: 'error',
          duration: 1000,
        });
      } else {
        this.getCode();
      }
    },
    // 获取验证码
    getCode() {
      api
        .Forget({ phone: this.Forget.phone })
        .then((res) => {
          if (res.message.success === true) {
            this.$message({
              message: res.message.message,
              type: 'success',
              duration: 1000,
            });
            this.AuthCode = false;
            this.auth_time = 60;
            const authTimetimer = setInterval(() => {
              this.auth_time -= 1;
              if (this.auth_time <= 0) {
                this.AuthCode = true;
                clearInterval(authTimetimer);
              }
            }, 1000);
          } else {
            this.$message({
              message: res.message.message,
              type: 'warning',
              duration: 1000,
            });
          }
        })
        .catch((mgs) => {
          this.$message({
            message: mgs.message.message,
            type: 'error',
            duration: 1000,
          });
        });
    },
    // 校验验证码格式
    // cheackCode() {
    //   let reg = /^\d{6}$/
    //   let res = reg.test(this.Forget.VerificationCode)
    //   return res
    // },
    // 重置密码
    ResetPassWord() {
      this.$refs.Forget.validate((valid) => {
        if (valid) {
          api
            .reSet({
              phone: this.Forget.phone,
              code: this.Forget.VerificationCode,
              password: this.Forget.PassWord,
            })
            .then((res) => {
              if (res.message.success) {
                this.$message({
                  message: '重置密码成功',
                  type: 'success',
                  duration: 1000,
                });
                this.goBack();
              } else {
                this.$message({
                  message: res.message.message,
                  type: 'error',
                  duration: 1000,
                });
              }
            })
            .catch((mgs) => {
              this.$message({
                message: mgs.message.message,
                type: 'error',
                duration: 1000,
              });
            });
        }
      });
      // if (this.Forget.phone.length <= 0) {
      //   this.$message({
      //     message: '手机号不能为空',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else if (!this.checkPhone()) {
      //   this.$message({
      //     message: '请输入正确的手机号',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else if (this.Forget.VerificationCode.length != 0) {
      //   this.$message({
      //     message: '验证码不能为空',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else if (!this.cheackCode()) {
      //   this.$message({
      //     message: '请输入正确验证码格式',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else if (this.Forget.PassWord != 0 && this.Forget.rePassWord != 0) {
      //   this.$message({
      //     message: '密码不能为空',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else if (this.Forget.PassWord !== this.Forget.rePassWord) {
      //   this.$message({
      //     message: '两次密码不一致',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else {
      //   api
      //     .reSet(this.Forget)
      //     .then(res => {
      //       if (res.message.success == true) {
      //         this.$message({
      //           message: res.message.message,
      //           type: 'success',
      //           duration: 1000
      //         })
      //         this.BackLogin()
      //       } else {
      //         this.$message({
      //           message: res.message.message,
      //           type: 'error',
      //           duration: 1000
      //         })
      //       }
      //     })
      //     .catch(mgs => {
      //       this.$message({
      //         message: mgs.message.message,
      //         type: 'error',
      //         duration: 1000
      //       })
      //     })
      // }
    },
    goBack() {
      this.$emit('rightViewType', this.rightViewType);
    },
  },
};
</script>
